// IE polyfills ...
import "core-js/stable";
import "regenerator-runtime/runtime";

import React from 'react';
import {render} from 'react-dom';

import './i18n';

// own css
import './styles/styles.scss';

import Calculator from "./app/calculator";
import Configurator from "./app/configurator";

if (process.env.NODE_ENV !== 'production') {
    require('./styles/style.css');
}

if (process.env.REACT_APP_MODE === "CALC") {
    render(
        <Calculator/>,
        document.getElementById('calculator')
    );
}
if (process.env.REACT_APP_MODE !== "CALC") {
    render(
        <Configurator/>,
        document.getElementById('configurator')
    );
}


if (process.env.NODE_ENV !== 'production') {
    window.login = () => {
        let formdata = new FormData();
        formdata.append('user', 'kunde');
        formdata.append('pass', 'kunde');
        formdata.append('submit', 'login');
        formdata.append('logintype', 'login');
        formdata.append('pid', 4);
        fetch('/index.php?id=3', {
            method: 'POST',
            body: formdata
        });
    };
}
