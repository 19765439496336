import {apiUrl} from "../rest/resolveAPIUrl";
import {useCallback, useState} from "react";

// const savedVehicles = [{
//     name: 'name',
//     query: 'query',
//     statusString: 'optionLogic.status_string'
// }];

function useCustomerSaveVehicles() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [savedVehicles, setSavedVehicles] = useState([]);

    const loadPreconfiguredVehicles = useCallback(async () => {
        setLoading(true);
        try {
            const url = new URL(apiUrl);
            url.searchParams.append('_action', 'listPreconfiguredVehicles');
            let result = await fetch(url.href, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });
            if (result.status > 200 || result.status < 200) {
                throw result;
            }
            result = await result.json();
            result = Object.values(result);
            setSavedVehicles(result);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setLoading(false);
        }
    }, []);

    const loadSavedVehicles = useCallback(async () => {
        setLoading(true);
        try {
            const url = new URL(apiUrl);
            url.searchParams.append('_action', 'listFeUserVehicles');
            let result = await fetch(url.href, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });
            if (result.status > 200 || result.status < 200) {
                throw result;
            }
            result = await result.json();
            result = Object.values(result);
            setSavedVehicles(result);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setLoading(false);
        }
    }, []);

    const addVehicle = useCallback(async (drilldownData, vehicleKey, pdfFile) => {
        try {
            const url = new URL(apiUrl);
            const formData = new FormData()
            formData.append('data', JSON.stringify({
                drilldownData,
                key: vehicleKey
            }));
            formData.append('pdf', pdfFile);

            url.searchParams.append('_action', 'saveFeUserVehicle');
            const result = await fetch(url.href, {
                method: 'POST',
                body: formData,
                credentials: 'include'
            });
            if (result.status > 200 || result.status < 200) {
                throw result;
            }
            await loadSavedVehicles();
        } catch (e) {
            console.log(e);
            throw e;
        }
    }, [loadSavedVehicles]);

    const deleteVehicle = useCallback(async (vehicleKey) => {
        try {
            const url = new URL(apiUrl);
            url.searchParams.append('_action', 'deleteFeUserVehicle');
            const result = await fetch(url.href, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    key: vehicleKey
                }),
                credentials: 'include'
            });
            if (result.status > 200 || result.status < 200) {
                throw result;
            }
            await loadSavedVehicles();
        } catch (e) {
            console.log(e);
            throw e;
        }
    }, [loadSavedVehicles]);

    return {
        loading,
        error,
        savedVehicles,
        loadSavedVehicles,
        loadPreconfiguredVehicles,
        addVehicle,
        deleteVehicle
    }
}

export {useCustomerSaveVehicles};