import React, {useContext} from 'react';
import ProgressBar from "react-bootstrap/lib/ProgressBar";
import FormGroup from "react-bootstrap/lib/FormGroup";
import {ConfigurationContext} from "./configuration-utils";

const FormProgress = () => {
    const {progress, total} = useContext(ConfigurationContext);
    const current = 100 / total * progress;
    return (
        <FormGroup controlId="formProgress" className={'configurator-progress'}>
            <ProgressBar bsStyle="warning" now={current} />
        </FormGroup>
    );
};

export default FormProgress;