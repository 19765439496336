import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de from './translations/de/common.json';
import en from './translations/en/common.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            ...en
        }
    },
    de: {
        translation: {
            ...de
        }
    }
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: false,
        resources,
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['cookie', 'localStorage'],
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            caches: ['localStorage'],
            excludeCacheFor: ['cimode'],
            htmlTag: document.documentElement,
            checkWhitelist: true
        }
    });

export default i18n;