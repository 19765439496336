import React, {useState} from "react";
import {useCustomerSaveVehicles} from "./useCustomerSaveVehicles";

const CustomerVehicleContext = React.createContext({});
const CustomerVehicleProvider = ({children}) => {
    const [selected, setSelected] = useState(null);
    const values = useCustomerSaveVehicles();

    return (
        <CustomerVehicleContext.Provider value={{...values, selected, setSelected}}>
            {children}
        </CustomerVehicleContext.Provider>
    )
};

export {CustomerVehicleContext, CustomerVehicleProvider}