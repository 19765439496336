import React, {useEffect, useMemo} from 'react';
import BsFormSelect from "../../../components/bs-form-select";
import {useResource} from "rest-hooks";
import {VehicleYearResource} from "../../../rest/APIResources";
import {useForm, useFormState} from "react-final-form";
import {sortNumerical} from "./sorting";
import {useTranslation} from 'react-i18next';

import union from "lodash/union";

export const reduceToName = ((previousValue, currentValue) => {
    previousValue.push(currentValue.name);
    return previousValue;
});

const YearSelect = ({onCarNotRequestable}) => {

    const form = useForm();
    const {values: {year}} = useFormState({
        subscription: {values: true}
    });

    const data = useResource(VehicleYearResource.detailShape(),{ year });
    const { t } = useTranslation();

    const years = useMemo(() => {
        const {savedVehicles, yearVehicles} = data;
        const yearSaved = savedVehicles?.drilldown.class_list[0].year_list
            .reduce(reduceToName, []) ?? [];
        const yearApi = yearVehicles?.drilldown.class_list[0].year_list
            .reduce(reduceToName, []) ?? [];
        return union(yearSaved, yearApi).sort(sortNumerical);
    }, [data]);

    useEffect(() => {
        if (year !== undefined && year !== "" && (years.length === 0 || years.indexOf(year) === -1)) {
            form.change('year', '');
            //onCarNotRequestable(true);
        } else if (year !== undefined && year !== "") {
            //onCarNotRequestable(false);
        }
    }, [year]);

    return (
        <BsFormSelect name={'year'} placeholder={t('year')} options={years}/>
    );
};
export default YearSelect;
