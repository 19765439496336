import React, {useEffect, useMemo, useState} from "react";
import {apiUrl} from "../../rest/resolveAPIUrl";
import {sortAlphabetical, sortNumerical} from "../form/components/sorting";
import {reduceToName} from "../form/components/year-select";

const useBackendFunctions = () => {
    const [currentVehicle, setCurrentVehicle] = useState({});
    const [backendSettings, setBackendSettings] = useState(null);
    const [vehicleData, setVehicleData] = useState(null);
    const backendData = useMemo(() => ({
        factorB2B: currentVehicle.factorBaseB2b ? currentVehicle.factorBaseB2b : 1.0000,
        factorB2C: currentVehicle.factorBaseB2c ? currentVehicle.factorBaseB2c : 1.0000,
        factorEquipmentB2B: currentVehicle.factorEquipmentB2b ? currentVehicle.factorEquipmentB2b : 1.0000,
        factorEquipmentB2C: currentVehicle.factorEquipmentB2c ? currentVehicle.factorEquipmentB2c : 1.0000,
        lkw: currentVehicle.lkw ? currentVehicle.lkw : undefined,
        dutyType: currentVehicle.dutyType ? currentVehicle.dutyType : "default",
        consumptionCity: currentVehicle.consumptionCity ? currentVehicle.consumptionCity : "16",
        consumptionOutsideCity: currentVehicle.consumptionOutsideCity ? currentVehicle.consumptionOutsideCity : "12",
        consumptionCombined: currentVehicle.consumptionCombined ? currentVehicle.consumptionCombined : "14",
        emissionClass: currentVehicle.emissionClass ? currentVehicle.emissionClass : "euro6a",
        co2EfficiencyClass: currentVehicle.co2EfficiencyClass ? currentVehicle.co2EfficiencyClass : "g",
        co2EmissionCombined: currentVehicle.co2EmissionCombined ? currentVehicle.co2EmissionCombined : "320",
        euronorm: currentVehicle.euronorm ? currentVehicle.euronorm : "euro4",
        deliveryTime: currentVehicle.deliveryTime ? currentVehicle.deliveryTime : "2",
        dno: currentVehicle.vehicleFeatures,
        vehicleFeatures: currentVehicle.vehicleFeatures,
        exportVehicle: currentVehicle.exportVehicle,
        export: currentVehicle.export,
        specialSale: currentVehicle.specialSale,
        vehicleDoors: currentVehicle.vehicleDoors ? currentVehicle.vehicleDoors : "FOUR_OR_FIVE",
        fuelType: currentVehicle.fuelType,
        airbags: currentVehicle.airbags,
        vehicleClass: currentVehicle.vehicleClass,
        vehicleCategory: currentVehicle.vehicleCategory,
        vehicleColors: currentVehicle.vehicleColors,
        loginPid: currentVehicle.loginPid,
    }), [currentVehicle]);

    useEffect(() => {
        if (!vehicleData) {
            const urlVehicles = new URL(apiUrl);
            urlVehicles.searchParams.append('_action', 'vehicleSearch');
            const loadData = async urlSettings => {
                const response = await fetch(urlSettings, {
                    method: 'POST',
                    credentials: "include"
                });
                const json = await response.json();
                setVehicleData(json);
            };
            loadData(urlVehicles);
        }
    }, []);

    useEffect(() => {
        if (!backendSettings) {
            const urlSettings = new URL(apiUrl);
            urlSettings.searchParams.append('_action', 'getSettings');
            const loadData = async urlSettings => {
                const response = await fetch(urlSettings, {
                    method: 'POST',
                    credentials: "include",
                    body: JSON.stringify({"settingsKeys":
                            [
                                "oldestYearShown",
                            ]})
                });
                const json = await response.json();
                setBackendSettings(json);
            };
            loadData(urlSettings);
        }
    }, []);

    const years = useMemo(() => {
        if (vehicleData && backendSettings) {
            const {yearVehicles} = vehicleData;
            const yearApi = yearVehicles?.drilldown.class_list[0].year_list
                .reduce((previousValue, currentValue) => {
                    if (parseInt(currentValue.name) >= parseInt(backendSettings.oldestYearShown)) {
                        previousValue.push(currentValue.name);
                    }
                    return previousValue;
                }, []) ?? [];
            return yearApi.sort(sortNumerical);
        }
    }, [vehicleData, backendSettings]);

    const brands = useMemo(() => {
        if (years && vehicleData) {
            const {yearVehicles} = vehicleData;
            let api = [];
            years.forEach(year => {
                const yearListApi = yearVehicles?.drilldown.class_list[0].year_list.filter(current => current.name === year);
                let yearBrands = [];
                if (yearListApi) {
                    if (yearListApi[0]) {
                        yearBrands = yearListApi[0].make_list.reduce(reduceToName, []);
                    }
                }
                yearBrands.forEach(yearBrand => {
                    if (api.indexOf(yearBrand) === -1) {
                        api.push(yearBrand);
                    }
                });
            })
            return api.sort(sortAlphabetical);
        }
        return []
    }, [years, vehicleData]);

    return {years, brands, currentVehicle, setCurrentVehicle, ...backendData};
};

const BackendContext = React.createContext({});
const BackendProvider = ({children}) => {
    const values = useBackendFunctions();
    return (
        <BackendContext.Provider value={values}>
            {children}
        </BackendContext.Provider>
    )
};

export {BackendContext, BackendProvider}