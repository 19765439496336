import React, {Suspense, useContext, useState} from 'react';
import {Form as FinalForm, useFormState} from "react-final-form";

import FormProgress from "../../components/form-field-progress";
import {Alert, Col, Row} from "react-bootstrap";

import YearSelect from "./components/year-select";
import BrandSelect from "./components/brand-select";
import ModelSelect from "./components/model-select";
import VersionSelect from "./components/version-select";
import InteriorSelect from "./components/interior-select";
import FormSubmitButton from "./components/form-submit-button";
import {ConfigurationContext} from "../../components/configuration-utils";
import FormStateListener from "./form-state-listener";
import Condition from "../../components/condition";
import LoadingSelect from "./components/loading-select";
import {NetworkErrorBoundary} from "rest-hooks";
import {useTranslation} from "react-i18next";

const getQueryVariables = (url) => {
    let params = {};
    const parser = document.createElement('a');
    parser.href = url;
    const query = parser.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
};

const CarNotRequestable = () => {
    const {values} = useFormState({
        subscription: {values: true}
    });
    const { t } = useTranslation();
    return (
        <Row>
            <Col xs={12}>
                <Alert bsStyle={"danger"} style={{marginLeft: 0, marginRight: 0}}>
                    <h4>{t('errorVehicleNotRequestable')}</h4>
                    <p>{t('errorChooseAnotherVehicle')}</p>
                </Alert>
            </Col>
        </Row>
    )
};

const Form = () => {
    const {
        initialQuery,
        query
    } = useContext(ConfigurationContext);

    const [notRequestable, setNotRequestable] = useState(false);

    const _handleSubmit = () => {
    };

    const _handleCarNotRequestable = (value) => {
        setNotRequestable(value);
    }

    const urlParams = getQueryVariables(window.configurator.targetPageUrl);

    return (
        <React.Fragment>
            <FinalForm
                onSubmit={_handleSubmit}
                subscription={{submitting: true, pristine: true}}
                initialValues={initialQuery}
            >
                {({handleSubmit}) => (
                    <form onSubmit={() => handleSubmit} method={"GET"} action={window.configurator.targetPageUrl}>
                        {urlParams && urlParams.id && <input type={"hidden"} value={urlParams.id} name={"id"}/>}
                        <FormStateListener/>
                        <FormProgress/>
                        <Row>
                            <Col xs={2} className={"configurator-input"}>
                                <Suspense fallback={<LoadingSelect name={"year"}/>}>
                                    <NetworkErrorBoundary fallbackComponent={(props) => <LoadingSelect name={"year"} {...props}/>}>
                                        <YearSelect onCarNotRequestable={_handleCarNotRequestable}/>
                                    </NetworkErrorBoundary>
                                </Suspense>
                            </Col>
                            <Col xs={2} className={"configurator-input"}>
                                <Suspense key={query.year} fallback={<LoadingSelect name={"brand"}/>}>
                                    <NetworkErrorBoundary fallbackComponent={(props) => <LoadingSelect name={"brand"} {...props}/>}>
                                        <BrandSelect onCarNotRequestable={_handleCarNotRequestable}/>
                                    </NetworkErrorBoundary>
                                </Suspense>
                            </Col>
                            <Col xs={2} className={"configurator-input"}>
                                <Suspense key={query.brand} fallback={<LoadingSelect name={"model"}/>}>
                                    <NetworkErrorBoundary fallbackComponent={(props) => <LoadingSelect name={"model"} {...props}/>}>
                                        <ModelSelect onCarNotRequestable={_handleCarNotRequestable}/>
                                    </NetworkErrorBoundary>
                                </Suspense>
                            </Col>
                            <Col xs={2} className={"configurator-input"}>
                                <Suspense key={query.model} fallback={<LoadingSelect name={"version"}/>}>
                                    <NetworkErrorBoundary fallbackComponent={(props) => <LoadingSelect name={"version"} {...props}/>}>
                                        <VersionSelect onCarNotRequestable={_handleCarNotRequestable}/>
                                    </NetworkErrorBoundary>
                                </Suspense>
                            </Col>
                            <Col xs={2} className={"configurator-input"}>
                                <Suspense key={query.version} fallback={<LoadingSelect name={"interior"}/>}>
                                    <NetworkErrorBoundary fallbackComponent={(props) => <LoadingSelect name={"interior"} {...props}/>}>
                                        <InteriorSelect onCarNotRequestable={_handleCarNotRequestable}/>
                                    </NetworkErrorBoundary>
                                </Suspense>
                            </Col>
                        </Row>
                        <Condition condition={window.configurator.showSubmit}>
                            <FormSubmitButton type={"submit"}/>
                        </Condition>
                        {notRequestable && (
                            <CarNotRequestable/>
                        )}
                    </form>
                )}
            </FinalForm>
        </React.Fragment>
    );
};

export default Form;

