import React, {lazy, Suspense, useEffect, useState} from 'react';

import FormView from "./form-view";
import AsyncLoader from "../components/async-loader";
import Condition from "../components/condition";
import {Grid, Row} from "react-bootstrap";
import {BackendProvider} from "./backend/backend-context";
import {CustomerVehicleProvider} from "../components/customer-vehicles";
import SavedVehicles from "./customer/saved-vehicles";
import {apiUrl} from "../rest/resolveAPIUrl";

export const isLoggedIn = window.configurator.loggedIn ? window.configurator.loggedIn === '1' : false;

const InfoView = lazy(() => import("./infos/info-view"));
const BackendView = lazy(() => import("./backend/backend-view"));

const Configurator = () => {
    // const [transferData, setTransferData] = useState(null);
    const CustomerVehicles = process.env.REACT_APP_MODE === "BE" || !isLoggedIn ? 'div' : CustomerVehicleProvider;
    const Backend = process.env.REACT_APP_MODE === "BE" ? BackendProvider : 'div';

    // const testPdF = () => {
    //     const url = new URL(apiUrl);
    //     url.searchParams.append('_action', 'initializeOrder');
    //     const initializeOrderData = {
    //         "vehicleId":"2017100126-0",
    //         "options":"1,2,6,8,11,19,20,25,40",
    //         "payment":"transfer",
    //         "priceType":"tax,duty",
    //         "differenBillingAdress":[]
    //     }
    //     const loadData = async url => {
    //         const response = await fetch(url, {
    //             method: 'POST',
    //             credentials: "include",
    //             body: JSON.stringify(initializeOrderData)
    //         });
    //         const json = await response.json();
    //         console.log("PDF TEST");
    //         console.log(json);
    //         const path = json.orderPdfPath.replace('/index.php', '');
    //         console.log(path);
    //         setTransferData(path);
    //
    //     };
    //     loadData(url);
    // }

    return (
        <Grid fluid>
            <div className={"configurator"} id={"configurator"}>
                {/*<button onClick={() => testPdF()}>Test PDF</button>*/}
                {/*{transferData &&*/}
                {/*    <a target="_blank" href={transferData}>PDF drucken</a>*/}
                {/*}*/}
                <Backend>
                    <CustomerVehicles>
                        <FormView/>
                        {process.env.REACT_APP_MODE === "FE" && (
                            <React.Fragment>
                                <Suspense fallback={<AsyncLoader/>}>
                                    <SavedVehicles/>
                                </Suspense>
                                <Condition condition={!window.configurator.showSubmit}>
                                    <Suspense fallback={<AsyncLoader/>}>
                                        <InfoView isLoggedIn={isLoggedIn}/>
                                    </Suspense>
                                </Condition>
                            </React.Fragment>
                        )}
                        {process.env.REACT_APP_MODE === "BE" && (
                            <Suspense fallback={<AsyncLoader/>}>
                                <BackendView/>
                            </Suspense>
                        )}
                    </CustomerVehicles>
                </Backend>
            </div>
        </Grid>
    );
};

export default Configurator;