export const sortAlphabetical = (a, b) => {
    const aVal = a.toUpperCase();
    const bVal = b.toUpperCase();
    if (aVal > bVal) {
        return 1;
    }
    if (aVal < bVal) {
        return -1
    }
    return 0;
};

export const sortByObjectKey = (key) => (a, b) =>  {
    const aVal = a[key].toUpperCase();
    const bVal = b[key].toUpperCase();
    if (aVal > bVal) {
        return 1;
    }
    if (aVal < bVal) {
        return -1
    }
    return 0;
};

export const sortNumerical = (a, b) => {
    return Number(b) - Number(a)
};