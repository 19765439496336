import React, {useContext, useEffect, useMemo} from 'react';
import {ConfigurationContext} from "../../../components/configuration-utils";
import BsFormSelect from "../../../components/bs-form-select";
import {useResource} from "rest-hooks";
import {MakeModelResource} from "../../../rest/APIResources";
import {useForm, useFormState} from "react-final-form";
import {sortByObjectKey} from "./sorting";
import {useTranslation} from 'react-i18next';
import union from "lodash/union";
import unionBy from "lodash/unionBy";

const filterVehicles = (vehiclesAll, model, version) => {
    if (typeof vehiclesAll?.model_list !== "string") {
        const series = vehiclesAll?.model_list.filter(current => current.name === model)[0];
        if (series) {
            const style = series.series_list.filter(current => current.name === version)[0];
            if (style) {
                return style.style_list.map(({uvc, uvc_tie_breaker, name}) => ({value: {uvc,  uvc_tie_breaker, name}, label: name}));
            }
        }
    }
};

const filterVehiclesAlternative = (vehiclesAll, model) => {
    if (vehiclesAll?.model_list !== 'empty') {
        const series = vehiclesAll?.model_list.filter(current => current.name === model)[0];
        if (series) {
            return series.series_list;
        }
    } else {
        return [];
    }
};

const InteriorApiSelect = ({onCarNotRequestable}) => {
    const form = useForm();
    const {values} = useFormState({
        subscription: {values: true}
    });
    const {values: {year, brand, model, version, interior}} = useFormState({
        subscription: {values: true}
    });
    const { t } = useTranslation();
    const data = useResource(MakeModelResource.detailShape(), {year, brand});

    const interiors = useMemo(() => {
        const {savedVehicles, yearVehicles} = data;
        if (model && model !== "" && version && version !== "") {
            const modelSaved = filterVehicles(savedVehicles, model, version);
            const modelApi = filterVehicles(yearVehicles, model, version);
            return unionBy(modelSaved, modelApi, 'label').sort(sortByObjectKey('label'));
        }
        if (model && model !== "") {
            let seriesSaved = filterVehiclesAlternative(savedVehicles, model);
            let seriesApi = filterVehiclesAlternative(yearVehicles, model);
            const series = union(seriesSaved, seriesApi);
            if (series.some(c => c.name !== "")) return [];
            let s = series.filter(current => current.name === "")[0];
            if (s) {
                return s.style_list
                    .reduce(((previousValue, currentValue) => {
                        previousValue.push(currentValue);
                        return previousValue;
                    }), [])
                    .map(({uvc, uvc_tie_breaker, name}) => ({value: {uvc,  uvc_tie_breaker, name}, label: name}))
                    .sort(sortByObjectKey('label'));
            }
        }
        return [];
    }, [data, model, version]);

    // let interiorsFiltered = [];
    // if (data.excludedVehicles && data.excludedVehicles.length > 0) {
    //     for (let i = 0; i < interiors.length; i++) {
    //         let isExcluded = false;
    //         for (let j = 0; j < data.excludedVehicles.length; j++) {
    //             if (interiors[i].value.uvc === data.excludedVehicles[j].uvc && interiors[i].value.uvc_tie_breaker === data.excludedVehicles[j].uvc_tie_breaker) {
    //                 isExcluded = true;
    //             }
    //         }
    //         if (!isExcluded) {
    //             interiorsFiltered.push(interiors[i]);
    //         }
    //     }
    // } else {
    //     interiorsFiltered = interiors;
    // }
    // let currentInteriorIsExcluded = false;
    // if (interior) {
    //     const currentInterior = JSON.parse(interior);
    //     if (currentInterior && currentInterior.uvc && currentInterior.uvc_tie_breaker) {
    //         if (data.excludedVehicles && data.excludedVehicles.length > 0) {
    //             for (let j = 0; j < data.excludedVehicles.length; j++) {
    //                 if (currentInterior.uvc === data.excludedVehicles[j].uvc && currentInterior.uvc_tie_breaker === data.excludedVehicles[j].uvc_tie_breaker) {
    //                     currentInteriorIsExcluded = true;
    //                 }
    //             }
    //         }
    //     }
    // }
    // if (currentInteriorIsExcluded) {
    //     form.change('interior', '');
    //     onCarNotRequestable(true);
    // } else {
    //     onCarNotRequestable(false);
    // }

    useEffect(() => {
        let currentInteriorIsExcluded = true;
        if (interior) {
            const currentInterior = JSON.parse(interior);
            if (currentInterior && currentInterior.uvc && currentInterior.uvc_tie_breaker) {
                if (interiors && interiors.length > 0) {
                    for (let i = 0; i < interiors.length; i++) {
                        if (currentInterior.uvc === interiors[i].value.uvc && currentInterior.uvc_tie_breaker === interiors[i].value.uvc_tie_breaker) {
                            currentInteriorIsExcluded = false;
                        }
                    }
                }
            }
        }
        if (interior !== undefined && interior !== "" && currentInteriorIsExcluded) {
            form.change('interior', '');
            onCarNotRequestable(true);
        } else if (interior !== undefined && interior !== "") {
            onCarNotRequestable(false);
        }
    }, [interior]);

    return <BsFormSelect name={'interior'} placeholder={t('style')} options={interiors}/>
};

const InteriorSelect = ({onCarNotRequestable}) => {
    const {progress} = useContext(ConfigurationContext);
    const {values: {year, brand}} = useFormState({
        subscription: {values: true}
    });
    const { t } = useTranslation();
    const disabled = progress < 2 || year === undefined || brand === undefined;
    if (disabled) {
        return <BsFormSelect name={'interior'} placeholder={t('style')} options={[]} disabled={disabled}/>
    } else {
        return <InteriorApiSelect onCarNotRequestable={onCarNotRequestable}/>
    }
};
export default InteriorSelect;
