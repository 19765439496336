import React from 'react';
// https://reacttraining.com/react-router/web/guides/quick-start
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
// https://github.com/pbeshai/use-query-params
import {QueryParamProvider} from "use-query-params";
import {CacheProvider} from "rest-hooks";

import ConfiguratorBase from "./views/configurator";
import ErrorView from "./views/error-view";
import {ConfigurationProvider} from "./components/configuration-utils";

const Configurator = () => (
    <CacheProvider>
        <Router basename={window.location.pathname}>
            <QueryParamProvider ReactRouterRoute={Route}>
                <ConfigurationProvider>
                    <Switch>
                        <Route exact path={"/"} component={ConfiguratorBase}/>
                        <Route component={ErrorView}/>
                    </Switch>
                </ConfigurationProvider>
            </QueryParamProvider>
        </Router>
    </CacheProvider>
);

export default Configurator;
