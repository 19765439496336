import React, {useContext} from 'react';
import {ConfigurationContext} from "../../../components/configuration-utils";
import {Button} from "react-bootstrap";

const FormSubmitButton = (props) => {
    const {progress, total} = useContext(ConfigurationContext);
    return (
        <Button bsStyle="warning" className={"btn-raised"} bsSize="lg" block type="submit" disabled={progress !== total} {...props}>
            Wunschfahrzeug konfigurieren
        </Button>
    );
};

export default FormSubmitButton;