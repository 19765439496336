const resolveAPIUrl = (): string => {
    let url: URL = new URL(window.location.href);
    try {
        if (window.configurator.apiUrl.startsWith('http')) {
            url = new URL(window.configurator.apiUrl);
        } else {
            url = new URL(window.location.origin + '/' + window.configurator.apiUrl);
        }
    } catch (e) {
        throw new Error("apiUrl invalid");
    }
    return url.href;
};
export const apiUrl = resolveAPIUrl();