import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import FormControl from "react-bootstrap/lib/FormControl";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormGroup from "react-bootstrap/lib/FormGroup";
import {Field} from "react-final-form";

const SelectWrapper = ({input, meta, children, ...rest}) => {
    return (
        <FormControl
            componentClass={"select"}
            {...input}
            {...rest}
        >
            {children}
        </FormControl>
    );
};

const BsFormSelect = (
    {
        name,
        placeholder,
        options,
        ...rest
    }
) => {

    const noOptions = useMemo(() => (options || []).every(item => {
        if (typeof item === "string") return item === "";
        return item.value === "";
    }), [options]);

    return (
        <FormGroup className={'has-warning'}>
            <ControlLabel>{placeholder}</ControlLabel>
            <i className="material-icons">arrow_drop_down</i>
            <Field name={name} component={SelectWrapper} disabled={noOptions} placeholder={placeholder} {...rest}>
                <option key={-1} hidden disabled value="">{placeholder}</option>
                {options.map((item, index) => {
                    if (typeof item === "string") {
                        return (
                            <option key={index} value={item}>{item}</option>
                        )
                    } else {
                        const {value, label} = item;
                        return (
                            <option key={index} value={JSON.stringify(value)}>{label}</option>
                        )
                    }
                })}
            </Field>
        </FormGroup>
    );
};

BsFormSelect.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    placeholder: PropTypes.string
};

export default BsFormSelect;