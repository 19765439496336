import React, {useContext, useEffect, useState} from 'react';
import {CustomerVehicleContext} from "../../components/customer-vehicles";
import {Button, Collapse, ListGroup, ListGroupItem} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const SavedVehicles = () => {
    const {
        savedVehicles,
        loading,
        error,
        loadSavedVehicles,
        deleteVehicle,
        selected,
        setSelected
    } = useContext(CustomerVehicleContext);
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (typeof loadSavedVehicles === "function") {
            loadSavedVehicles();
        }
        // eslint-disable-next-line
    }, []);

    if (savedVehicles === undefined || savedVehicles.length === 0 ) return null;


    const handleDeleteVehicle = (name) => (event) => {
        event.stopPropagation();
        event.preventDefault();
        const result = window.confirm(t('confirmDelete'));
        if (result) {
            deleteVehicle(name);
        }
    };

    return (
        <div className={"savedVehicles"}>
            <Button variant={"primary"} block disabled={loading || error} onClick={() => setOpen(!open)}>
                <span>
                {selected ? selected.name : t('savedVehiclesNoConfig')} <i
                    className="material-icons">{`keyboard_arrow_${open ? 'up' : 'down'}`}</i>
                </span>
            </Button>
            <div className={'text-left'}>
                <Collapse in={open}>
                    <div className={""}>
                        <ListGroup>
                            {(savedVehicles || []).map((vehicle, idx) => (
                                <ListGroupItem key={idx} onClick={() => {
                                    setSelected(vehicle);
                                    setOpen(false);
                                }}>
                                    {vehicle.name} <span onClick={handleDeleteVehicle(vehicle.name)} className={"pull-right text-danger"}><i className="material-icons">delete_outline</i></span>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

export default SavedVehicles;