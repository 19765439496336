import React from 'react';

const AsyncLoader = () => {
    return (
        <div className={"configurator-loading configurator-info"} style={{height: '680px'}}>
            <div className={"configurator-loading-icon"}>
                <i className="material-icons">directions_car</i>
                <div className="progress">
                    <div className="indeterminate"/>
                </div>
            </div>
        </div>
    );
};

export default AsyncLoader;