import React from 'react';
import Form from "./form/form";

function FormView() {
    return (
        <div className={'configurator-form'} id={'configurator-form'}>
            <Form/>
        </div>
    );
}

export default FormView;
