import React from 'react';
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import {HelpBlock} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const LoadingSelect = ({name, error = null}) => {
    const isError = error && error.status > 300;
    const {t} = useTranslation();
    return (
        <FormGroup validationState={isError ? 'error' : null}>
            <ControlLabel>{isError ? t('error') : t('loading') + "..."}</ControlLabel>
            <i className="material-icons">arrow_drop_down</i>
            <FormControl componentClass={"select"} disabled name={name}>
                {!isError && <option>{t('pleaseWait')}</option>}
                {isError && <option>{t('noDataFound')}</option>}
            </FormControl>
            <HelpBlock>{t('tryAnotherOption')}</HelpBlock>
        </FormGroup>
    );
};

export default LoadingSelect;