import React from 'react';
import {Form, Field} from "react-final-form";
import {Col, Row} from "react-bootstrap";
import createDecorator from 'final-form-calculate';
import { useTranslation } from 'react-i18next';

const MWST = window.configurator.mwst ? window.configurator.mwst : 1.19;
const ZOLL = window.configurator.zoll ? window.configurator.zoll: 1.1;
const ZOLLLKW = window.configurator.zolllkw ? window.configurator.zolllkw : 1.22;
const exchangeRate = window.configurator.exchange.rate ? window.configurator.exchange.rate : 1.10315;

const ensureNumber = (value) => {
    return Number(value) ? Number(value) : 0;
};

const calcZoll = (value, isTruck) => {
    let tax = (isTruck === "true") ? ZOLLLKW : ZOLL;
    return (ensureNumber(value) * (tax-1)).toFixed(2);
};

const calcMwst = (value) => {
    return (ensureNumber(value) * (MWST-1)).toFixed(2);
};

const calcCurrency = (inputThis, inputPrev, inputOther, UStoEU) => {
    let newV = (UStoEU ? ensureNumber(inputThis)/exchangeRate : ensureNumber(inputThis)*exchangeRate).toFixed(2);
    let oldV = ensureNumber(inputPrev).toFixed(2);
    return (newV !== oldV && Math.abs(oldV - newV) > 0.02) ? newV : inputOther;
};

const calcTotal= (value) => {
    let price = [
        ensureNumber(value.inputPriceUS)/exchangeRate,
        ensureNumber(value.inputLogisticUS)/exchangeRate,
        ensureNumber(value.zoll),
        ensureNumber(value.mwst),
        ensureNumber(value.inputHomologation)
    ];
    return price.reduce((sum, add) => sum + add).toFixed(2);
};

const calculator = createDecorator(
    {
        field: 'inputPriceUS',
        updates: {
            inputPriceEU: (inputPriceUS, allValues, prevValues) => calcCurrency(inputPriceUS, prevValues.inputPriceEU, allValues.inputPriceEU, true),
            zoll: (inputPriceUS, allValues) => calcZoll(ensureNumber(inputPriceUS)/exchangeRate + ensureNumber(allValues.inputLogisticUS)/exchangeRate, allValues.isTruck)
        }
    },{
        field: 'inputPriceEU',
        updates: {
            inputPriceUS: (inputPriceEU, allValues, prevValues) => calcCurrency(inputPriceEU, prevValues.inputPriceUS, allValues.inputPriceUS, false),
            zoll: (inputPriceEU, allValues) => calcZoll(ensureNumber(inputPriceEU) + ensureNumber(allValues.inputLogisticUS)/exchangeRate, allValues.isTruck)
        }
    },{
        field: 'inputLogisticUS',
        updates: {
            logisticEU: (inputLogisticUS) => "(=" + (ensureNumber(inputLogisticUS)/exchangeRate).toFixed(2).toString() + " €)",
            zoll: (inputLogisticUS, allValues) => calcZoll(ensureNumber(allValues.inputPriceUS)/exchangeRate + ensureNumber(inputLogisticUS)/exchangeRate, allValues.isTruck)
        }
    },{
        field: 'isTruck',
        updates: {
            inputLogisticUS: (isTruck) => isTruck === "true" ? "2500" : "2000",
            inputHomologation: (isTruck) => isTruck === "true" ? "2500" : "2000",
            zoll: (isTruck, allValues) => calcZoll(ensureNumber(allValues.inputPriceUS)/exchangeRate + ensureNumber(allValues.inputLogisticUS)/exchangeRate, isTruck)
        }
    },{
        field: 'zoll',
        updates: {
            mwst: (zoll, allValues) => calcMwst(ensureNumber(allValues.inputPriceUS)/exchangeRate + ensureNumber(allValues.inputLogisticUS)/exchangeRate + ensureNumber(zoll))
        }
    },{
        field: 'mwst',
        updates: {
            totalEU: (mwst, allValues) => calcTotal(allValues)
        }
    },{
        field: 'inputHomologation',
        updates: {
            totalEU: (inputHomologation, allValues) => calcTotal(allValues)
        }
    }
);

const InputField = ({label, name, placeholder, disabled = false, plus = false}) => {
    return (
        <Row>
            <Col xs={12} sm={6}>
                <label htmlFor={name}>{label}</label>
            </Col>
            <Col xs={12} sm={6}>
                {plus && (<span className={"iconPlus"}>+</span>)}
                <Field
                    disabled={disabled}
                    name={name}
                    component="input"
                    type="number"
                    placeholder={placeholder}
                    id={name}
                />
            </Col>
        </Row>
    );
};

const ImportCalculator = () => {
    const _handleSubmit = () => {

    };
    const { t } = useTranslation();
    return (
        <div className={"import-calculator"}>
            <Form
                onSubmit={_handleSubmit}
                decorators={[calculator]}
                initialValues={{ isTruck: 'false' }}
            >
                {({handleSubmit, values}) => (
                    <form onSubmit={() => handleSubmit} method={"GET"}>

                        <Row>
                            <Col xs={12}>
                                <Field
                                    name="isTruck"
                                    component="input"
                                    type="radio"
                                    value="false"
                                    id={"isTruckFalse"}
                                />
                                <label htmlFor={"isTruckFalse"} className={"radioLabel"}>
                                    {t('car')}
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Field
                                    name="isTruck"
                                    component="input"
                                    type="radio"
                                    value="true"
                                    id={"isTruckTrue"}
                                />
                                <label htmlFor={"isTruckTrue"} className={"radioLabel"}>
                                    {t('truck')}
                                </label>
                            </Col>
                        </Row>

                        <hr/>

                        <InputField
                            label={t('price') + " $"}
                            name={"inputPriceUS"}
                            placeholder={0}
                            value={24}
                        />

                        <InputField
                            label={t('price') + " €"}
                            name={"inputPriceEU"}
                            placeholder={0}
                        />

                        <InputField
                            label={t('logistics') + " in $"}
                            name={"inputLogisticUS"}
                            placeholder={0}
                            plus={true}
                        />

                        <Row>
                            <Col xs={12} sm={6}>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Field
                                    className={"field-logistic"}
                                    name="logisticEU"
                                    readOnly
                                    component="input"
                                    type="text"
                                    placeholder={"(= 0 €)"}
                                />
                            </Col>
                        </Row>
                        <br/>

                        <InputField
                            label={t('customs') + " in €"}
                            name={"zoll"}
                            disabled={true}
                            placeholder={0}
                            plus={true}
                        />

                        <InputField
                            label={t('tax') + " in €"}
                            name={"mwst"}
                            disabled={true}
                            placeholder={0}
                            plus={true}
                        />

                        <InputField
                            label={t('homologation') + " in €"}
                            name={"inputHomologation"}
                            placeholder={0}
                            plus={true}
                        />

                        <hr/>

                        <Row>
                            <Col xs={12} sm={6}>
                                <h1>{t('importPrice')} in €</h1>
                            </Col>
                            <Col xs={12} sm={6}>
                                <span className={"iconPlus"}>=</span>
                                <Field
                                    className={"field-total"}
                                    name="totalEU"
                                    readOnly
                                    component="input"
                                    type="number"
                                    placeholder={0}
                                />
                            </Col>
                        </Row>

                        <br/>

                        {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
                    </form>
                )}
            </Form>
        </div>
    );
};

export default ImportCalculator;

