// eslint-disable-next-line
import React from 'react';
import PropTypes from "prop-types";

const Condition = ({condition, children}) => {
    return condition === true ? children : null;
};

Condition.propTypes = {
    condition: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired
};

export default Condition;